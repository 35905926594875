// 推广状态
export default {
  map: {
    1: '已上线',
    2: '已下线',
  },
  enum: {
    online: 1,
    offline: 2,
  },
};
